<template>
  <div>
    <a-card
      :bordered="false"
      class="header-solid h-full"
      style="padding: 10px"
      :bodyStyle="{ padding: 0 }"
    >
      <template #title>
        <a-row style="width: 100%; padding: 10px">
          <div style="display: flex; justify-content: space-between">
            <a-input-search
              placeholder="Search by exercise name"
              v-model="txtSearch"
              style="width: 300px"
            />
            <div style="text-align: end">
              <v-btn color="primary" dark @click="handleSubmit"
                >Create Test
              </v-btn>
            </div>
          </div>
        </a-row>
      </template>
      <a-table
        style="max-height: 85vh; overflow-y: scroll; padding: 10px"
        v-if="filterData"
        :columns="columns"
        :data-source="filterData"
        :pagination="{
          pageSize: 10,
          total: filterData.length,
        }"
      >
        <template slot="testName" slot-scope="record">
          <h6>{{ record.testName }}</h6>
          <p style="color: gray">{{ record.testDescription }}</p>
        </template>
        <template slot="bookTestName" slot-scope="record">
          <h6>{{ record.bookTestName }}</h6>
        </template>
        <!-- <template slot="totalTests" slot-scope="record">
          <h6>
            {{
              record.listSubjectTest.length ? record.listSubjectTest.length : 0
            }}
          </h6>
        </template> -->
        <template slot="totalQuestions" slot-scope="totalQuestions">
          <h6>{{ totalQuestions }}</h6>
        </template>
        <template slot="totalStudents" slot-scope="totalStudents">
          <h6>{{ totalStudents }}</h6>
        </template>
        <!-- 
        <template slot="totalStudents" slot-scope="totalStudents">
          <h6>{{ totalStudents }}</h6>
        </template> -->

        <template slot="totalTopics" slot-scope="totalTopics">
          <h6>{{ totalTopics }}</h6>
        </template>
        <template slot="testType" slot-scope="testType">
          <div class="author-info">
            <a-tag
              class="tag-status"
              :class="
                testType === 'reading' ? 'ant-tag-danger' : 'ant-tag-primary'
              "
            >
              {{ testType === "reading" ? "Reading" : "Listening" }}
            </a-tag>
          </div>
        </template>
        <template slot="deadline" slot-scope="record">
          <div>
            <h6>
              {{ moment(record.createdAt).format("hh:mm, DD/MM/YYYY") }}
            </h6>
            <h6>
              {{ moment(record.updatedAt).format("hh:mm, DD/MM/YYYY") }}
            </h6>
          </div>
        </template>
        <template slot="createdUser" slot-scope="createdUser">
          <div class="table-avatar-info">
            <a-avatar shape="square" :src="createdUser.avatar" />
            <div class="avatar-info">
              <h6>{{ createdUser.name }}</h6>
              <p>{{ createdUser.email }}</p>
            </div>
          </div>
        </template>
        <template
          slot="action"
          slot-scope="record"
          v-if="
            userProfile.role === 'teacher' ||
            userProfile.role === 'admin' ||
            userProfile.role === 'testCT'
          "
        >
          <a-dropdown>
            <a class="icon-more" @click="(e) => e.preventDefault()">
              <a-icon
                type="more"
                style="font-size: 20px; transform: rotate(90deg)"
            /></a>
            <a-menu slot="overlay">
              <a-menu-item @click="viewDetailTest(record)">
                <a-icon type="eye" /> View Detail
              </a-menu-item>
              <a-menu-item @click="editTest(record)">
                <a-icon type="edit" /> Edit Test
              </a-menu-item>
              <a-menu-item @click="addTestToClass(record)">
                <a-icon type="usergroup-add" /> Add Test To Class
              </a-menu-item>
              <a-menu-item @click="addAttachment(record)">
                <a-icon type="upload" /> Add attachments
              </a-menu-item>
              <a-menu-item @click="handleOpenPopupCopyTest(record)">
                <a-icon type="copy" /> Copy test
              </a-menu-item>
              <a-menu-item
                v-if="userProfile.role !== 'testCT'"
                @click="confirmDelete(record)"
                style="display: flex"
              >
                <a-icon type="delete" style="color: red; margin-top: 5px" />
                <p style="color: red">Delete Test</p>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
        <!-- <template slot="expandedRowRender" slot-scope="record">
          <a-table
            :columns="columns"
            :data-source="record.listSubjectTest ? record.listSubjectTest : []"
            :pagination="{
              pageSize: 5,
              total: record.listSubjectTest.length,
            }"
            rowKey="_id"
            style="background: white; margin-left: -5.4rem"
          >
            <template slot="testName" slot-scope="record">
              <h6>{{ record.testName }}</h6>
              <p style="color: gray">{{ record.testDescription }}</p>
            </template>

            <template slot="totalQuestions" slot-scope="totalQuestions">
              <h6>{{ totalQuestions }}</h6>
            </template>
            <template slot="totalStudents" slot-scope="totalStudents">
              <h6>{{ totalStudents }}</h6>
            </template>
            
        <template slot="totalStudents" slot-scope="totalStudents">
          <h6>{{ totalStudents }}</h6>
        </template>

            <template slot="totalTopics" slot-scope="totalTopics">
              <h6>{{ totalTopics }}</h6>
            </template>
            <template slot="status" slot-scope="isHide">
              <div class="author-info">
                <a-tag
                  class="tag-status"
                  :class="isHide ? 'ant-tag-muted' : 'ant-tag-primary'"
                >
                  {{ isHide ? "Đã ẩn" : "Chưa ẩn" }}
                </a-tag>
              </div>
            </template>
            <template slot="deadline" slot-scope="record">
              <div>
                <h6>
                  {{ moment(record.startDate).format("DD/MM/YYYY") }}
                </h6>
                <h6>
                  {{ moment(record.endDate).format("DD/MM/YYYY") }}
                </h6>
              </div>
            </template>
            <template slot="createdUser" slot-scope="createdUser">
              <div class="table-avatar-info">
                <a-avatar shape="square" :src="createdUser.avatar" />
                <div class="avatar-info">
                  <h6>{{ createdUser.name }}</h6>
                  <p>{{ createdUser.email }}</p>
                </div>
              </div>
            </template>
            <template
              slot="action"
              slot-scope="record"
              v-if="userProfile.role === 'teacher'"
            >
              <a-dropdown>
                <a class="icon-more" @click="(e) => e.preventDefault()">
                  <a-icon
                    type="more"
                    style="font-size: 20px; transform: rotate(90deg)"
                /></a>
                <a-menu slot="overlay">
                  <a-menu-item @click="viewDetailTest(record)">
                    <a-icon type="eye" /> Xem chi tiết
                  </a-menu-item>
                  <a-menu-item @click="editTest(record)">
                    <a-icon type="edit" /> Chỉnh sửa test
                  </a-menu-item>
                  <a-menu-item @click="addTestToClass(record)">
                    <a-icon type="usergroup-add" /> Thêm test vào lớp
                  </a-menu-item>
                  <a-menu-item
                    @click="confirmDelete(record)"
                    style="display: flex"
                  >
                    <a-icon type="delete" style="color: red; margin-top: 5px" />
                    <p style="color: red">Xóa test</p>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </template>
          </a-table>
        </template> -->
      </a-table>
      <TableLoading v-else />
    </a-card>
    <a-modal
      title="Add test to class"
      :visible="visibleAddClass"
      :confirm-loading="addClassLoading"
      @ok="handleAddTestToClass"
      @cancel="() => (visibleAddClass = false)"
    >
      <div style="padding: 10px">
        <b>Class List</b>
        <a-select
          mode="multiple"
          :size="size"
          placeholder="Choose classes to add test to  "
          style="width: 100%; margin-top: 1rem"
          v-model="selectedClasses"
        >
          <a-select-option v-for="item in listClass" :key="item._id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>
    <a-modal
      title="Attachments"
      :visible="visibleAddAttachment"
      :confirm-loading="addAttachmentLoading"
      :footer="null"
      @cancel="() => (visibleAddAttachment = false)"
    >
      <div style="padding: 10px">
        <a-list bordered>
          <a-list-item
            v-for="(file, index) in fileList"
            :key="file.name"
            style="
              padding: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div style="display: flex; align-items: center">
              <a-icon
                :type="getFileIcon(file.name)"
                style="margin-right: 10px; color: #1890ff"
              />
              <span>{{ file.name }}</span>
            </div>
            <a-icon
              type="delete"
              @click="deleteFile(index)"
              style="color: red; cursor: pointer"
            />
          </a-list-item>
        </a-list>
        <div style="display: flex; justify-content: end">
          <input
            type="file"
            ref="file"
            style="display: none"
            @change="chooseFile"
            multiple
          />
          <v-btn
            :loading="addAttachmentLoading"
            color="primary"
            dark
            style="margin-top: 1rem"
            class="ma-2 white--text responsive-document-item"
            @click="$refs.file.click()"
          >
            Add file
            <v-icon right dark> mdi-cloud-upload </v-icon>
          </v-btn>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="Session to copy"
      :visible="visibleSessionToCopy"
      :confirm-loading="sessionToCopyLoading"
      :footer="null"
      @cancel="() => (visibleSessionToCopy = false)"
    >
      <div style="padding: 10px" v-if="listTopics">
        <a-select
          :size="size"
          mode="multiple"
          placeholder="Choose test to clone question  "
          style="width: 100%"
          @change="handleCloneTest"
          @search="handleSearch"
        >
          <a-select-option v-for="item in filterDataSelect" :key="item._id">
            {{ item.testName }}
          </a-select-option>
        </a-select>
        <p style="margin-top: 1rem; font-weight: bold">Current test</p>
        <a-collapse
          v-for="(topic, topicIndex) in listTopics"
          :key="topic._id"
          :bordered="false"
        >
          <template #expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel :key="topicIndex + 1" :header="topic.topicName">
            <template #extra>
              <a-icon
                height="2em"
                width="2em"
                @click="
                  (e) => {
                    e.stopPropagation();
                    handleRemoveTopic(topicIndex);
                  }
                "
                type="delete"
                style="
                  margin-top: -6px;
                  position: absolute;
                  color: red;
                  top: 50%;
                  right: 4%;
                "
              />
            </template>
            <div
              v-for="(
                questionGroup, questionGroupIndex
              ) in topic.listQuestionGroups"
              :key="questionGroup.content"
            >
              <a-collapse :bordered="false">
                <template #expandIcon="props">
                  <a-icon
                    type="caret-right"
                    :rotate="props.isActive ? 90 : 0"
                  />
                </template>
                <a-collapse-panel
                  key="1"
                  :header="`Part ${questionGroupIndex + 1}`"
                >
                  <template #extra>
                    <a-icon
                      height="2em"
                      width="2em"
                      @click="
                        (e) => {
                          e.stopPropagation();
                          handleRemoveGroup(questionGroupIndex, topicIndex);
                        }
                      "
                      type="delete"
                      style="
                        margin-top: -6px;
                        position: absolute;
                        color: red;
                        top: 50%;
                        right: 4%;
                      "
                    />
                  </template>
                  <div
                    v-for="question in questionGroup.listQuestions"
                    :key="question.content"
                  >
                    <span style="margin: 2px 0px 2px 20px; font-weight: 600">
                      {{ question.questionNumber }}
                    </span>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
      <div style="display: flex; justify-content: end">
        <v-btn
          :loading="addAttachmentLoading"
          color="primary"
          dark
          style="margin-top: 1rem"
          class="ma-2 white--text responsive-document-item"
          @click="handleCopyTest"
        >
          Copy test
          <v-icon right dark> mdi-content-copy </v-icon>
        </v-btn>
      </div>
    </a-modal>
    <a-modal
      title="Delete test"
      :visible="visibleDeleteTest"
      :confirm-loading="deleteTestLoading"
      ok-text="Xác nhận"
      cancel-text="Hủy"
      @ok="handleDeleteTest"
      @cancel="() => (visibleDeleteTest = false)"
    >
      Are you sure you want to delete this test?
    </a-modal>
  </div>
</template>

<script>
import { NotificationMixin } from "../../mixin/Notification";
import { FirebaseStorageMixin } from "../../mixin/FirebaseStorage";
import { LocalStorageMixin } from "../../mixin/LocalStorage";
import TableLoading from "../Base/TableLoading.vue";
import Test from "../../api/Test";
import Class from "../../api/Class";
export default {
  mixins: [NotificationMixin, LocalStorageMixin, FirebaseStorageMixin],
  components: {
    TableLoading,
  },

  data() {
    return {
      // Active button for the "Authors" table's card header radio button group.
      authorsHeaderBtns: "all",
      isAdd: false,
      deleteData: null,
      visibleAddClass: false,
      confirmLoading: false,
      txtSearch: "",
      txtSearchSelect: "",
      listTest: null,
      selectedClasses: [],
      activeKey: ["1"],
      selectedId: null,
      subjectColumns: [
        {
          title: "Tên môn học",
          key: "name",
          scopedSlots: { customRender: "subject" },
        },
        {
          title: "Số lượng bài kiểm tra",
          key: "totalTests",
          scopedSlots: { customRender: "totalTests" },
        },
      ],
      columns: [
        {
          dataIndex: "",
          key: "testName",
          title: "Test Name",
          scopedSlots: { customRender: "testName" },
        },
        {
          title: "Total Questions",
          dataIndex: "totalQuestions",
          key: "totalQuestions",
          scopedSlots: { customRender: "totalQuestions" },
        },
        {
          dataIndex: "",
          key: "bookTestName",
          title: "Book Test Name",
          scopedSlots: { customRender: "bookTestName" },
        },
        // {
        //   title: "Total Submit",
        //   dataIndex: "totalStudents",
        //   key: "totalStudents",
        //   scopedSlots: { customRender: "totalStudents" },
        // },
        {
          title: "Topic Topics",
          key: "totalTopics",
          dataIndex: "totalTopics",
          scopedSlots: { customRender: "totalTopics" },
        },
        {
          title: "Test Type ",
          key: "testType",
          dataIndex: "testType",
          scopedSlots: { customRender: "testType" },
        },
        {
          title: "Test Creation - Update",
          key: "deadline",
          dataIndex: "",
          scopedSlots: { customRender: "deadline" },
        },
        // {
        //   title: "Tạo bởi ",
        //   key: "createdUser",
        //   dataIndex: "createdUser",
        //   scopedSlots: { customRender: "createdUser" },
        // },
        {
          title: "",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      size: "default",
      listClass: [],
      visibleAddAttachment: false,
      visibleSessionToCopy: false,
      sessionToCopyLoading: false,
      page: 1,
      addClassLoading: false,
      visibleDeleteTest: false,
      deleteTestLoading: false,
      currentTest: null,
      listTopics: [],
      addAttachmentLoading: false,
      fileList: [],
    };
  },
  mounted() {
    Test.getAllTestLighter(
      new URLSearchParams([
        ["limit", 1000],
        ["page", this.page],
      ])
    )
      .then((response) => {
        this.listTest = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
    Class.getListClass(
      new URLSearchParams([
        ["limit", 1000],
        ["page", this.page],
      ])
    )
      .then((response) => {
        this.listClass = response.data.data;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    viewDetailTest(payload) {
      this.$router.push({ name: "detailTest", params: { id: payload._id } });
    },
    handleSearch(value) {
      this.txtSearchSelect = value;
    },
    handleRemoveTopic(topicIndex) {
      this.listTopics = this.listTopics.filter(
        (item, index) => index !== topicIndex
      );
    },
    handleRemoveGroup(questionGroupIndex, topicIndex) {
      this.listTopics[topicIndex].listQuestionGroups = this.listTopics[
        topicIndex
      ].listQuestionGroups.filter(
        (item, index) => index !== questionGroupIndex
      );
    },
    editTest(payload) {
      this.$router.push({ name: "editTest", params: { id: payload._id } });
    },
    handleCloneTest(payload) {
      let listTopics = [];
      payload.map((id) => {
        listTopics.push(
          ...this.filterData
            .find((item) => item._id === id)
            .listTopics.map((item) => ({
              ...item,
              testId: id,
            }))
        );
      });

      this.listTopics = [...this.listTopics, ...listTopics];
      this.listTopics = this.listTopics.filter(
        (item) => !item.testId || payload.includes(item.testId)
      );
    },
    addAttachment(payload) {
      this.visibleAddAttachment = true;
      this.fileList = payload.attachments;
      this.currentTest = payload;
    },
    async chooseFile(event) {
      const files = Array.from(event.target.files); // Convert FileList to array
      this.addAttachmentLoading = true;

      try {
        // Upload all files and collect URLs
        const uploadedAttachments = await Promise.all(
          files.map(async (file) => {
            const fileName = `class/test/${this.currentTest._id}/${file.name}`;
            const url = await FirebaseStorageMixin.methods.uploadFileToFireBase(
              file,
              fileName
            );

            return {
              name: file.name,
              url: url,
              status: "done",
            };
          })
        );

        // Update the test with all attachments at once
        await Test.updateTest(this.currentTest._id, {
          attachments: [
            ...this.currentTest.attachments,
            ...uploadedAttachments,
          ],
        });

        // Add the uploaded files to fileList and notify the user
        this.fileList.push(...uploadedAttachments);

        this.openNotificationWithIcon(
          "success",
          "Files uploaded and added to test successfully"
        );
      } catch (e) {
        this.openNotificationWithIcon(
          "error",
          "Failed to upload files",
          e.message
        );
      } finally {
        this.addAttachmentLoading = false;
      }
    },
    handleCopyTest(payload) {
      this.$router.push({
        name: "editTest",
        params: { id: this.currentTest._id },
        query: { copy: true, listTopics: JSON.stringify(this.listTopics) },
      });
      setTimeout(() => {
        this.listTopics = [];
        this.currentTest = null;
      }, 200);
    },
    handleOpenPopupCopyTest(payload) {
      this.currentTest = payload;
      this.listTopics = this.currentTest.listTopics;
      this.visibleSessionToCopy = true;
    },
    deleteFile(index) {
      try {
        Test.updateTest(this.currentTest._id, {
          attachments: [...this.fileList.filter((_, i) => i !== index)],
        })
          .then((response) => {
            this.openNotificationWithIcon(
              "success",
              "Remove attachment successfully"
            );
            this.fileList.splice(index, 1); // Remove the file at the specified index

            this.addAttachmentLoading = false;
          })
          .catch((error) => {
            this.openNotificationWithIcon(
              "error",
              "Add attachment failed",
              error.message
            );

            this.addAttachmentLoading = false;
          });
      } catch (e) {
        console.log(e);
        this.addAttachmentLoading = false;
      }
    },
    handleDeleteTest() {
      this.deleteTestLoading = true;
      Test.deleteTest(this.selectedId)
        .then((response) => {
          this.openNotificationWithIcon("success", "Delete successfully");
          this.deleteTestLoading = false;
          this.visibleDeleteTest = false;
          Test.getAllTest(
            new URLSearchParams([
              ["limit", 1000],
              ["page", this.page],
            ])
          )
            .then((response) => {
              this.listTest = response.data.data;
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((err) => {
          this.openNotificationWithIcon("error", "Delete fail");
          this.deleteTestLoading = false;
          this.selectedId = null;
          this.visibleDeleteTest = false;
        });
    },
    addTestToClass(payload) {
      this.visibleAddClass = true;
      this.selectedClasses = payload.classIds;
      this.selectedId = payload._id;
    },
    handleAddTestToClass() {
      this.addClassLoading = true;
      Test.updateClassTest(this.selectedId, { classIds: this.selectedClasses })
        .then((response) => {
          this.openNotificationWithIcon(
            "success",
            "Add test to classes successfully"
          );
          this.visibleAddClass = false;
          this.selectedId = null;
          this.selectedClasses = [];
          this.addClassLoading = false;
          this.userProfile.role !== "admin" &&
          this.userProfile.role !== "teacher"
            ? Test.getListTest(
                new URLSearchParams([
                  ["limit", 100],
                  ["page", this.page],
                ])
              )
                .then((response) => {
                  this.listTest = response.data.data;
                })
                .catch((e) => {
                  console.log(e);
                })
            : Test.getAllTest(
                new URLSearchParams([
                  ["limit", 1000],
                  ["page", this.page],
                ])
              )
                .then((response) => {
                  this.listTest = response.data.data;
                })
                .catch((e) => {
                  console.log(e);
                });
        })
        .catch((err) => {
          this.visibleAddClass = false;
          this.selectedId = null;
          this.selectedClasses = [];
          this.addClassLoading = false;
        });
    },
    confirmDelete(payload) {
      this.visibleDeleteTest = true;
      this.selectedId = payload._id;
    },
    handleSubmit() {
      this.$router.push("/test/add");
    },
    renderFileItem(file) {
      return (
        <a-list-item>
          <a-icon type="file" style="margin-right: 10px" />
          <span>{file.name}</span>
        </a-list-item>
      );
    },
    getFileIcon(fileName) {
      if (!fileName) {
        return;
      }
      const extension = fileName.split(".").pop().toLowerCase();
      switch (extension) {
        case "pdf":
          return "file-pdf";
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
          return "file-image";
        case "doc":
        case "docx":
          return "file-word";
        case "xls":
        case "xlsx":
          return "file-excel";
        case "ppt":
        case "pptx":
          return "file-ppt";
        case "zip":
        case "rar":
          return "file-zip";
        case "txt":
          return "file-text";
        default:
          return "file";
      }
    },
  },
  computed: {
    classId() {
      return this.$route.params.id;
    },
    filterData() {
      var search = new RegExp(this.txtSearch, "i");
      return this.listTest?.filter(
        (item) =>
          search.test(item.testName) || search.test(item.testDescription)
      );
    },
    filterDataSelect() {
      var search = new RegExp(this.txtSearchSelect, "i");
      return this.listTest?.filter(
        (item) =>
          search.test(item.testName) || search.test(item.testDescription)
      );
    },
    userProfile() {
      return JSON.parse(localStorage.getItem("userProfile"));
    },
  },
};
</script>
